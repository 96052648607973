import Typography from '@mui/material/Typography'

import type { Variant } from '@mui/material/styles/createTypography'

// TODO get fonts for GT brand identity and setup provider/theme to use them in all UI elements
// import '@fontsource/roboto/300.css'
// import '@fontsource/roboto/400.css'
// import '@fontsource/roboto/500.css'
// import '@fontsource/roboto/700.css'

export { Typography }
export type { Variant }

export default Typography

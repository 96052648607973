import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import './Loader.css'

interface LoaderProps {
  style?: object
}

export const Loader: React.FC<LoaderProps> = ({ style }) => {
  return (
    <Box className='gt-loader' style={style}>
      <CircularProgress />
    </Box>
  )
}

export default Loader

import type { ComponentType } from 'react'
import { Broken } from 'components/Broken'
import { useData } from 'hooks/useData'
import Loader from 'components/Loader'

interface DataOptions {
  showError?: boolean
  showLoader?: boolean
}

export function withData<TProps> (
  WrappedComponent: ComponentType<TProps>,
  options?: DataOptions
) {
  return (props: TProps) => {
    const { data, error, filters, filterData, ready, loading } = useData()

    // TODO improve error handler
    if (error && options?.showError) {
      return <Broken />
    }

    // TODO check distinction between loader and ready states
    if ((!ready || loading) && options?.showLoader) {
      return <Loader />
    }

    return (
      <WrappedComponent
        {...data}
        filters={filters}
        filterData={filterData}
        ready
        loading
        error
        {...props}
      />
    )
  }
}

export default withData
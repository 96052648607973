import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Get } from 'utils/xhr'
import { localFilter } from 'utils/filter'
import type { Filters, FilterValue, FilterOptions } from 'utils/filter'

export interface DataProviderParams {
  // filters: { name: string, value: FilterValue, options: FilterValue[] }
  // Record<string, FilterValue[]>
  filters: Filters
}

export interface DataProviderProps {
  src?: string
  initialData?: any
  params?: DataProviderParams
  type?: string
  properties?: object
  children?: React.ReactNode
}

export interface DataProviderContext<T extends object = object> {
  src?: string
  data?: T[]
  properties?: object

  error?: object

  loading?: boolean
  ready?: boolean

  params?: object
  fetchData?: Function
  filterData?: Function

  filters?: Filters
}

const x: DataProviderContext = {}
export const DataContext = React.createContext<DataProviderContext>(x)

export const DataProvider = ({
  src,
  params,
  properties,
  children,
  initialData
}: DataProviderProps): JSX.Element => {
  const [error, setError] = useState<object | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [ready, setReady] = useState<boolean>(initialData ? true : false)

  const [data, setData] = useState<any>({ data: initialData })
  const [originalData, setOriginalData] = useState<any>({ data: initialData })

  const [filters, setFilters] = useState<Filters>({})

  const fetchData = useCallback(async (src: string) => {
    // TODO add support for CSV responses?
    // https://www.papaparse.com/docs
    try {
      setLoading(true)
      const { data } = await Get(src)

      setData(Array.isArray(data) ? { data } : data)
      setOriginalData(Array.isArray(data) ? { data } : data)

      setLoading(false)
      setReady(true)
    } catch (error: any) {
      setError(error)
      setLoading(false)
      setReady(false)
    }
  }, [])

  const filterData = useCallback(
    async (name: string, value: FilterValue | undefined) => {
      if (value === undefined) {
        delete filters[name].value
      } else {
        filters[name].value = value
      }

      setFilters(filters)
      setData({ data: localFilter(originalData.data, filters) })
    },
    [filters, originalData]
  )

  // TODO possible race condition here, need a better way to handle the initial filter values
  useEffect(() => {
    setData({ data: localFilter(originalData.data, filters) })
  }, [filters, originalData])

  useEffect(() => {
    if (params?.filters) {
      const filters: Filters = {}
      Object.entries(params.filters).forEach(([name, filter]) => {
        if (Array.isArray(filter.options)) {
          const options: FilterOptions = {}
          filter.options.forEach(option => {
            options[option] = option
          })

          filter.options = options
        }

        filters[name] = filter
      })

      setFilters(filters)
    }

    if (src) {
      fetchData(src)
    }
  }, [src, initialData, params, fetchData])

  const contextValue = useMemo<DataProviderContext>(
    () => ({
      src,
      data,
      properties,
      error,

      loading,
      ready,

      params,
      fetchData,
      filterData,

      filters
    }),
    [
      src,
      data,
      properties,
      error,
      loading,
      ready,
      params,
      fetchData,
      filterData,
      filters
    ]
  )

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  )
}

import classNames from 'classnames'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import ErrorIcon from '@mui/icons-material/Error'
import Typography from 'components/Typography'

import './Broken.css'

export interface BrokenProps {
  className?: string
  style?: Object
}

// TODO outlined? (again card)
// TODO test coverage for variant overrides
export const Broken = ({ className, style }: BrokenProps): JSX.Element => {
  return (
    <Card
      className={classNames('gt-broken', className)}
      style={style}
      square
      elevation={0}
    >
      <CardContent className='gt-broken__content'>
        <ErrorIcon />
        <Typography component='p'>
          An error occurred. Please try again later.
        </Typography>
      </CardContent>
    </Card>
  )
}

export default Broken
